


















































































































































































































































.permissoes {
  border-radius: 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .titulo {
      margin-bottom: 0;
    }
  }

  &-panel {
    .v-expansion-panel-header {
      font-size: 14px;
      font-weight: 600;
      padding-left: 20px;
      padding-right: 20px;
    }

    &-icon {
      &--expanded {
        flex-grow: 0 !important;
        margin-right: 10px;
      }
    }

    .v-expansion-panel--active > .v-expansion-panel-header {
      min-height: 48px;
      // margin-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .v-expansion-panels:not(.v-expansion-panels--accordion) > .v-expansion-panel--active {
      border-radius: 0;
    }

  }
  &-lista {
    .v-list-item__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .v-icon {
        font-size: 15px;
      }
    }

    &-menu {
      .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }

      .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
      }

      .v-input--selection-controls__input {
        min-height: 24px;
      }

      .v-list-item {
        min-height: 24px;
        padding: 5px 15px;
      }

      .strong {
        font-weight: bold;
        .v-label {
          color: #000;
        }

        &.theme--light.v-input--is-disabled .v-label {
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
  }

  .subitem {
    padding-left: 10px;
  }
}
